import React, { Component } from 'react';
import Select from 'react-select';
import appConfig from '../../appConfig';

class SelectObj extends Component {
  // static defaultProps = {}
  constructor(props){
    super(props);
    this.state = {
      selectedOption: props.selected
    };
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.props.options !== nextProps.options || this.props.selected !== nextProps.selected;
  // }
  componentDidUpdate(){

    setTimeout(()=>{
      const { selected } = this.props;
      const selectedOption = this.buildSelectedOption();

      let selectedVal = selected;
      selectedVal = (selectedVal === undefined) ? null : selectedVal;
      let selectedOptionVal = selectedOption ? selectedOption.value : null;
      selectedOptionVal = (selectedOptionVal === undefined) ? null : selectedOptionVal;

      if(selectedVal !== selectedOptionVal){
        this.updateData(selectedOption);
      }
    }, 200)
  }
  updateData(o){
    const { updateEditorData } = this.props;

    this.setState({
      selectedOption: o
    });

    updateEditorData && updateEditorData(o.value);
  }
  buildSelectedOption(){
    const { selected } = this.props;

    let selectedOption;
    if(this.props.selected ){
      selectedOption = {
        label: selected,
        value: selected,
      };
    }
    return selectedOption;
  }
  render() {
    const { isStylized } = this.props;
    const { disabled } = this.props;

    const selectedOption = this.buildSelectedOption();

    let options = this.props.options || [];
    const optionList = options.map((option)=>{
      return({
        label: option,
        value: option,
      });
    });


    const optionStyles = isStylized ? {
      menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const paddingRight = '5px';
        const paddingLeft = '5px';
        const border = '1px solid #f1f2f4';
        const boxShadow = '0 10px 15px rgba(190, 190, 208, 0.2)';
        return {
          ...styles,
          paddingRight,
          paddingLeft,
          border,
          boxShadow,
        };
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        console.log(data);
        const color = '#fff';
        const borderRadius = '3px';
        const display = 'inline-block';
        const width = 'auto';
        const float = 'left';
        const clear = 'both';
        const padding = '2px 8px';
        const margin = '5px 0';
        const fontSize = '85%';
        const backgroundColor = appConfig.colors[data.label];
        return {
          ...styles,
          borderRadius,
          color,
          display,
          width,
          float,
          clear,
          padding,
          margin,
          fontSize,
          backgroundColor,
        };
      },
      singleValue: (styles, { data }) => {
        const borderRadius = '3px';
        const color = '#fff !important';
        const padding = '2px 8px !important';
        const display = 'flex';
        const width = 'auto';
        const backgroundColor = appConfig.colors[data.label];
        return {
          ...styles,
          borderRadius,
          color,
          padding,
          display,
          width,
          backgroundColor
         }
      },
    } : {};


    return (
      <Select
        styles={optionStyles}
        value={selectedOption}
        onChange={(o)=>{ this.updateData(o) }}
        options={optionList}
        isSearchable={true}
        isDisabled={disabled}
        classNamePrefix='select'
      />
    );
  }
}

export default SelectObj
