import React, { Component } from 'react';
import Select from 'react-select';
import OptionManager from './OptionManager';
import OptionManagerWithParent from './OptionManagerWithParent';
import utils from '../../helpers/utils';

class SelectObj extends Component {
  // constructor(props){
  //   super(props);
  // }
  componentDidUpdate(prevProps){

    // check selectedOption against selected
    setTimeout(()=>{
      const { selected } = this.props;
      const selectedOption = this.buildSelectedOption();

      let selectedId = selected ? selected.id : null;
      selectedId = (selectedId === undefined) ? null : selectedId;
      let selectedOptionId = selectedOption ? selectedOption.value.id : null;
      selectedOptionId = (selectedOptionId === undefined) ? null : selectedOptionId;

      if(selectedId !== selectedOptionId){
        this.updateData(selectedOption);
      }
    }, 200)
  }
  updateData(o){
    const { updateEditorData } = this.props;

    const data = o ? o.value : null;
    updateEditorData && updateEditorData(data);
  }
  buildSelectedOption(){
    let { selected, options } = this.props;
    options = options || [];

    let selectedOption = null;
    if(this.props.selected){
      const selectedFound = options.filter((o)=>{
        let attrToCompare;
        if(selected.code){
          attrToCompare = 'code';
        } else{
          attrToCompare = 'name';
        }
        return (o.id === selected.id && o[attrToCompare] === selected[attrToCompare] && o.default_visibility === selected.default_visibility)
      });

      if(selectedFound.length > 0){
        selectedOption = {
          label: selected.code || selected.name,
          value: selected,
        };
      }
    }
    return selectedOption;
  }
  render() {
    const { disabled, optionManagerEnabled, optionManagerTitle, selectedParent, parentOptions, addOption, updOption, delOption } = this.props;

    let { options, childOptions } = this.props;
    options = options || [];
    childOptions = childOptions || [];

    const selectedOption = this.buildSelectedOption();

    // sort options
    options = utils.sortByKey(options, 'code', true, true);

    const optionList = options.map((option)=>{
      option.code = option.code || option.name
      return({
        label: option.code,
        value: option,
      });
    });

    return (
      <>
      <div className="d-flex">
        <div className="mr-auto" style={{width: '100%'}}>
          <Select
            value={selectedOption}
            onChange={(o)=>{ this.updateData(o) }}
            options={optionList}
            isSearchable={true}
            isClearable={true}
            isDisabled={disabled}
            classNamePrefix='select'
            />
        </div>

        { (!disabled && optionManagerEnabled) &&
        <div className="ml-1">
          { !parentOptions &&
            <OptionManager
              selected={ selectedOption }
              options={ options }
              optionManagerTitle={ optionManagerTitle }
              addOption={(optionData)=>{ addOption(optionData) }}
              updOption={(optionData)=>{ updOption(optionData) }}
              delOption={(optionData)=>{ delOption(optionData) }}
              />
          }

          { parentOptions &&
            <OptionManagerWithParent
              selected={ selectedOption }
              options={ childOptions }
              selectedParent={ selectedParent }
              parentOptions={ parentOptions}
              optionManagerTitle={ optionManagerTitle }
              addOption={(optionData)=>{ addOption(optionData) }}
              updOption={(optionData)=>{ updOption(optionData) }}
              delOption={(optionData)=>{ delOption(optionData) }}
              />
          }
        </div>
        }
      </div>
      </>
    );
  }
}

export default SelectObj
