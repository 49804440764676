import cloneDeep from 'lodash/cloneDeep';
import ActionTypes from "../actionTypes/optionActionTypes";
import appConfig from '../../appConfig';

const initialState = {};
let updOptionState;

const OptionReducer = (optionState = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COUNTRY_SUCCESS:
      updOptionState = Object.assign({}, optionState);
      updOptionState.country = action.payload
      return updOptionState ;

    case ActionTypes.FETCH_CITY_SUCCESS:
      updOptionState = Object.assign({}, optionState);
      updOptionState.city = action.payload
      return updOptionState ;

    case ActionTypes.FETCH_GENDER_SUCCESS:
      updOptionState = Object.assign({}, optionState);
      updOptionState.gender = action.payload
      return updOptionState ;

    case ActionTypes.FETCH_TYPE_SUCCESS:
      updOptionState = Object.assign({}, optionState);
      updOptionState.type = action.payload
      return updOptionState ;

    case ActionTypes.FETCH_CLASSIFICATION_SUCCESS:
      updOptionState = Object.assign({}, optionState);
      updOptionState.classification = action.payload
      return updOptionState ;

    case ActionTypes.FETCH_CLASS_SUCCESS:
      updOptionState = Object.assign({}, optionState);
      updOptionState.class = action.payload
      return updOptionState ;

    case ActionTypes.FETCH_MARKETING_SUCCESS:
      updOptionState = Object.assign({}, optionState);
      updOptionState.marketing = {
        management_presence: action.payload.filter((item)=>(item.type === 'management_presence')),
        visual: action.payload.filter((item)=>(item.type === 'visual')),
        watch: action.payload.filter((item)=>(item.type === 'watch')),
        ambassadors: action.payload.filter((item)=>(item.type === 'ambassadors')),
        longines_timing_specificities: action.payload.filter((item)=>(item.type === 'longines_timing_specificities')),
      }
      return updOptionState ;

    case ActionTypes.FETCH_ALL_SUCCESS:
      updOptionState = Object.assign({}, optionState);
      updOptionState = action.payload
      updOptionState.status = appConfig.status;
      return updOptionState ;

    case ActionTypes.ADD_OPTION:
      updOptionState = cloneDeep(optionState);

      // updOptionState.recentlyAdded = action.payload;

      // store in collection
      if(action.marketingItemType){
        updOptionState[action.optionType][action.marketingItemType].push(action.payload);
      } else {
        updOptionState[action.optionType].push(action.payload);
      }


      return updOptionState;

    case ActionTypes.UPD_OPTION:
      updOptionState = cloneDeep(optionState);

      // store in collection
      if(action.marketingItemType){
        const posOfIdToUpd = updOptionState[action.optionType][action.marketingItemType].findIndex((e) => {
          return e.id === action.payload.id;
        });

        // store in collection
        updOptionState[action.optionType][action.marketingItemType][posOfIdToUpd] = action.payload;

      } else {
        const posOfIdToUpd = updOptionState[action.optionType].findIndex((e) => {
          return e.id === action.payload.id;
        });

        // store in collection
        updOptionState[action.optionType][posOfIdToUpd] = action.payload;
      }

      return updOptionState;

      case ActionTypes.DEL_OPTION:
        updOptionState = cloneDeep(optionState);
        const id = action.payload;

        // store in collection
        if(action.marketingItemType){
          const posOfId = updOptionState[action.optionType][action.marketingItemType].findIndex((e) => {
            return e.id === id;
          });

          // store in collection
          updOptionState[action.optionType][action.marketingItemType] = [ ...updOptionState[action.optionType][action.marketingItemType].slice(0, posOfId), ...updOptionState[action.optionType][action.marketingItemType].slice(posOfId + 1)];
        } else {
          const posOfId = updOptionState[action.optionType].findIndex((e) => {
            return e.id === id;
          });

          // store in collection
          updOptionState[action.optionType] = [ ...updOptionState[action.optionType].slice(0, posOfId), ...updOptionState[action.optionType].slice(posOfId + 1)];
        }

        return updOptionState;

    default :
      return optionState;
  }

};

export default OptionReducer
