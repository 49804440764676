import React, { Component } from 'react';

class Avatar extends Component {
  static defaultProps = {
   size: "std"
  }
  render() {
    const { avatar, size, className } = this.props;

    let classNames = (size === 'sm') ? 'avatar-sm' : '';
    classNames = classNames + ' ' + className; 

    return(
      <div className={"avatar " + classNames}>
        <img src={avatar} alt="avatar" className="rounded-circle" />
      </div>
    )
  }
}

export default Avatar
