import utils from '../../helpers/utils';

const actions = [
  "ADD_ITEM",
  "DEL_ITEM",
  "UPD_ITEM",

  "FETCH_CHUNK",
  "FETCH_CHUNK_BEGIN",
  "FETCH_CHUNK_SUCCESS",
  "FETCH_CHUNK_ERROR",
];

export default utils.redux.createActionTypes('USER', actions );
