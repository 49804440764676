import React from "react";

export default ({isLoading, error}) => {
  // Handle the loading state
  if (isLoading) {
    return null; //<div>Loading...</div>;
  }
  // Handle the error state
  else if (error) {
    return <div className="text-center"><p className="lead">Sorry, there was a problem loading the page</p></div>;
  }
  else {
    return null;
  }
}
