import React, { Component } from 'react';
import "loaders.css";

class Loader extends Component {
  static defaultProps = {
    mini: false
  }
  render() {
    const { mini } = this.props;

    const className = mini ? ' loading loading-mini' : ' loading';
    return (
      <div className={"d-flex" + className}>
        <div className="loader m-auto">
           <div className="line-scale-pulse-out">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
           </div>
        </div>
      </div>
    );
  }
}

export default Loader
