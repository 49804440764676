import React, { Component } from 'react';
import Select from 'react-select';
import { Modal } from '../../components/Button';
import { Input } from '../../components/Input';
import { Row, Tabs, Tab } from 'react-bootstrap';
import utils from '../../helpers/utils';

class MultiSelectObj extends Component {
  // constructor(props){
  //   super(props);
  // }
  updateData(data){
    const { updateEditorData } = this.props;

    const selectedOption = data ? data.map((option)=>(option.value)) : null;
    updateEditorData && updateEditorData(selectedOption);
  }
  render() {
    const { disabled } = this.props;
    let { selected, options } = this.props;
    selected = selected || [];
    options = options || [];

    // sort options
    options = utils.sortByKey(options, 'code', true, true);

    const selectedOption = selected.map((option)=>({
        label: option.label || option.code || option.name,
        value: option,
      }));

    const optionList = options.map((option)=>{
      option.code = option.code || option.name
      return({
        label: option.code,
        value: option,
      });
    });

    return (
      <>
      <div className="d-flex">
        <div className="mr-auto" style={{width: '100%'}}>
          <Select
            value={selectedOption}
            onChange={(o)=>{ this.updateData(o) }}
            options={optionList}
            isSearchable={true}
            isClearable={true}
            isDisabled={disabled}
            classNamePrefix='select'
            isMulti={true}
          />
        </div>
      </div>
      </>
    );
  }
}

export default MultiSelectObj
