import utils from '../../helpers/utils';

const actions = [
  "CLEAR_COLLECTION",
  "CLEAR_RECENTLY_ADDED",

  "ADD_ITEM",
  "ADD_ITEM_BEGIN",
  "ADD_ITEM_SUCCESS",
  "ADD_ITEM_ERROR",

  "UPD_ITEM",
  "UPD_ITEM_BEGIN",
  "UPD_ITEM_SUCCESS",
  "UPD_ITEM_ERROR",

  "DEL_ITEM",
  "DEL_ITEM_BEGIN",
  "DEL_ITEM_SUCCESS",
  "DEL_ITEM_ERROR",

  "DUPL_ITEM",
  "DUPL_ITEM_BEGIN",
  "DUPL_ITEM_SUCCESS",
  "DUPL_ITEM_ERROR",

  "LOCK_ITEM",
  "UNLOCK_ITEM",

  "FETCH_ITEM",
  "FETCH_ITEM_BEGIN",
  "FETCH_ITEM_SUCCESS",
  "FETCH_ITEM_ERROR",

  "FETCH_YEAR",
  "FETCH_YEAR_BEGIN",
  "FETCH_YEAR_SUCCESS",
  "FETCH_YEAR_ERROR",

  "FETCH_LAST",
  "FETCH_LAST_BEGIN",
  "FETCH_LAST_SUCCESS",
  "FETCH_LAST_ERROR",

  "ADD_COMMENT",
  "ADD_COMMENT_BEGIN",
  "ADD_COMMENT_SUCCESS",
  "ADD_COMMENT_ERROR",

  "DEL_COMMENT",

  "UPD_FILTERS",
  "RESET_FILTERS",

  "EXPORT"
];

export default utils.redux.createActionTypes('EVENT', actions );
