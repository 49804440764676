import cloneDeep from 'lodash/cloneDeep';
import ActionTypes from "../actionTypes/userActionTypes";

const initialState = {
  collection : [],
  recentlyAdded: null
}
const ItemReducer = (itemsState = initialState, action) => {
  switch (action.type) {

    case ActionTypes.ADD_ITEM:
      return [...itemsState, action.payload] ;

    case ActionTypes.DEL_ITEM:
        let i = action.payload;
        return  [ ...itemsState.slice(0, i), ...itemsState.slice(i + 1)];

    case ActionTypes.UPD_ITEM:
        const updatedItemState = cloneDeep(itemsState);

        const posOfIdToUpd = itemsState.collection.findIndex((e) => {
          return e.id === action.payload.id;
        });

        updatedItemState.collection[posOfIdToUpd] = action.payload;

        // create if not found
        if(posOfIdToUpd < 0){
          updatedItemState.push(action.payload);
        }

        return  updatedItemState;

    case ActionTypes.FETCH_CHUNK_SUCCESS:
        let fetchedState = cloneDeep(itemsState);

        fetchedState.collection = [...action.payload];

        return fetchedState ;

    default:
        return itemsState;

  }
};

export default ItemReducer
