import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import $ from 'jquery';


var previewFirstFile = (files, selector) => {
  if (files && files[0]) {
    var reader = new FileReader();

    reader.onload = function(e) {
      $(selector).attr('src', e.target.result);
    }

    reader.readAsDataURL(files[0]);
    $(selector).show();
  }
}


// var _URL = window.URL || window.webkitURL;
function FileUploader(props) {

  const [filesToUpload, setFilesToUpload] = useState([]);

  const addFilesToUpload = (files) => {
    setFilesToUpload(files);
    if(props.updateEditorData){
      props.updateEditorData(files)
    }
  }

  const removeFilesToUpload = (posToRemove) => {
    let rmState = [ ...filesToUpload.slice(0, posToRemove), ...filesToUpload.slice(posToRemove + 1)];
    setFilesToUpload(rmState);
    if(props.updateEditorData){
      props.updateEditorData(rmState)
    }
  }


  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles = acceptedFiles || acceptedFiles;
    // Do something with the files
    if(props.selector){
      previewFirstFile(acceptedFiles, props.selector);
    }

    addFilesToUpload([...filesToUpload, ...acceptedFiles]);

  }, [filesToUpload])

  const fileInUploadList = filesToUpload.map((file, i)=>(
      <li key={ "file-to-upload-" +i } onClick={(file)=>{ removeFilesToUpload(i); }}>
        <div>{file.name}</div>
        <div>{file.type}</div>
        <div>{file.size} octet</div>
        <div className="mr-0"><i className="fas fa-times"></i></div>
      </li>
    ));

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const activeClassName = (isDragActive) ? 'active' : '';

  return (
    <div className={"file-uploader border border-light " + activeClassName}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p className="text-center"><i className="fas fa-cloud-upload-alt"></i> Drop the files here ...</p> :
            <p className="text-center"><i className="fas fa-cloud-upload-alt"></i> Drag 'n' drop some files here, or click to select files</p>
        }
      </div>

      { !props.selector && fileInUploadList.length > 0 &&
        <div>
          <ul className="file-uploader-list">
            {fileInUploadList}
          </ul>
        </div>
      }
    </div>
  )
}

export default FileUploader
