import React, { Component } from 'react';
import Avatar from './Avatar';

class User extends Component {
  // static defaultProps = {}
  render() {
    const { avatar, name, role, size } = this.props;

    return(
      <div className="user-card d-flex align-items-center">
        <Avatar avatar={avatar} size={size} />
        <div className="user-info">
          <h5 className="user-name mb-0">{name}</h5>
          <div className="user-role">{role}</div>
        </div>
      </div>
    )
  }
}

export default User
