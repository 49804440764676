export default {
  // api_url: 'https://lon-emp.swatchgroup.net/api',
  // api_url: 'https://emp.longines.com/api',
  api_url: 'https://emp.longines.alterngo.ch/api',
  status: ['confirmed', 'pending', 'cancelled'],
  role: ['Read Only', 'Partial Control', 'Full Control'],
  colors: {
    'confirmed': '#05C322',
    'pending': '#F78E00',
    'cancelled': '#AF0000',
  },
  settings: {
    ajax_polling_interval: 20000,
    lock_duration: 600000, //600000
  }
}
