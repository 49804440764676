import React, { Component } from 'react';
import $ from 'jquery';

class BackToTop extends Component {
  goToTop(){
    $("html, body").animate({scrollTop: 0}, 0);
  }
  render() {
    return (
      <div className="back-to-top" onClick={()=>{ this.goToTop() }}>
        <i className="fas fa-arrow-up"></i>
      </div>
    );
  }
}

export default BackToTop;
