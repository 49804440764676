import events from './eventsMock';
import users from './usersMock';
import logs from './logsMock';
import options from './optionsMock';

export default {
  events,
  users,
  logs,
  options
}
