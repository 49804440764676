import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class StatResume extends Component {
  static defaultProps = {
   stats: []
  }
  render() {
    const { label, value, stats, linkTo } = this.props;
    return (
      <Col lg="3" md="6" sm="12">
        { linkTo &&
        <Link to={linkTo}>
          <div className="widget stat-resume">
            <Row className="align-items-center">
              <Col xs={5} className="flex-column">
                <div className="my-auto text-center">
                  <span className="lead">
                    {value}
                  </span>
                  <h3 className="text-center">{label}</h3>
                </div>
              </Col>
              <Col xs={7} className="flex-column">
                <ul className="list-unstyled my-auto">
                  {
                    stats.map((el, i) => (
                      <li key={i}>
                        <span>{el}</span>
                      </li>))
                  }
                </ul>
              </Col>
            </Row>
          </div>
        </Link>
        }

        { !linkTo && 
          <div className="widget stat-resume">
            <Row className="align-items-center">
              <Col xs={5} className="flex-column">
                <div className="my-auto text-center">
                  <span className="lead">
                    {value}
                  </span>
                  <h3 className="text-center">{label}</h3>
                </div>
              </Col>
              <Col xs={7} className="flex-column">
                <ul className="list-unstyled my-auto">
                  {
                    stats.map((el, i) => (
                      <li key={i}>
                        <span>{el}</span>
                      </li>))
                  }
                </ul>
              </Col>
            </Row>
          </div>
        }
        
      </Col>
    );
  }
}

export default StatResume
