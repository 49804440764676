import appConfig from '../../appConfig';
import ActionTypes from "../actionTypes/eventActionTypes";
import axios from '../../axiosConfig';
import utils from '../../helpers/utils';
import * as AppActions from "./appActions";

const API_URL = appConfig.api_url;

export const clearCollection = events => ({ type: ActionTypes.CLEAR_COLLECTION, payload: [] });
export const clearRecentlyAdded = () => ({ type: ActionTypes.CLEAR_RECENTLY_ADDED, payload: null });

// update filters
export const updFilters = filters => ({ type: ActionTypes.UPD_FILTERS, payload: filters });
export const resetFilters = () => ({ type: ActionTypes.RESET_FILTERS, payload: null });


// export const delItem = (year, index) => ({ type: ActionTypes.DEL_ITEM, payload: index, year: year });
export const delItem = (year, id) => ((dispatch) => {

  const data = id;

  axios({
      method: 'delete',
      url: `${API_URL}/events/${id}`,
      data,
  }).then(response => {
      // let  data = response.data.data;
      dispatch(({ type: ActionTypes.DEL_ITEM, payload: id, year: year }))
  }).catch( error => {
      dispatch(AppActions.messageError('Oops Something goes wrong. Please retry in a few seconds'));
  })
})


export const addItem = (year, item) => ((dispatch) => {

  const data = utils.toFormData(item);

  let method = '';
  let actionMessageVerb;
  if(item.id === 'new'){
    item.id = '';
    actionMessageVerb = 'created';
  }

  axios({
      method: 'post',
      url: `${API_URL}/events/${item.id}${method}`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
      let  data = response.data.data;
      dispatch(AppActions.messageSuccess(`Event successfully ${actionMessageVerb}`));
      dispatch(({ type: ActionTypes.ADD_ITEM, payload: data, year: year }))
  }).catch( error => {
      dispatch(AppActions.messageError('Oops Something goes wrong. Please retry in a few seconds'));
  })
})

export const updItem = (year, item) => ((dispatch) => {

  const data = utils.toFormData(item);

  let method = '';
  let actionMessageVerb;
  if(item.id === 'new'){
    item.id = '';
    actionMessageVerb = 'created';
  } else {
    method = '?_method=put';
    actionMessageVerb = 'updated';
  }

  axios({
      method: 'post',
      url: `${API_URL}/events/${item.id}${method}`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
      let  data = response.data.data;
      dispatch(AppActions.messageSuccess(`Event successfully ${actionMessageVerb}`));
      dispatch(({ type: ActionTypes.UPD_ITEM, payload: data, year: year }))
  }).catch( error => {
      dispatch(AppActions.messageError('Oops Something goes wrong. Please retry in a few seconds'));
  })
})
// export const updItemBegin = () => ({ type: ActionTypes.UPD_ITEM_BEGIN });
// export const updItemSuccess = (year, item) => ({ type: ActionTypes.UPD_ITEM_SUCCESS, payload: item, year: year });
// export const updItemError = error => ({ type: ActionTypes.UPD_ITEM_ERROR, payload: error });

export const duplicateItem = (years, item) => ((dispatch) => {

  const data = {
    years: years
  };

  axios({
      method: 'post',
      url: `${API_URL}/events/${item.id}/duplicate`,
      data,
  }).then(response => {
      let  data = response.data.data;
      dispatch(AppActions.messageSuccess(`Event successfully duplicated`));
      dispatch(({ type: ActionTypes.DUPL_ITEM, payload: data }))
  }).catch( error => {
      dispatch(AppActions.messageError('Oops Something goes wrong. Please retry in a few seconds'));
  })
})

export const lockItem = (id) => ((dispatch) => {

  const data = {
    id: id
  };

  axios({
      method: 'post',
      url: `${API_URL}/events/${id}/lock`,
      data
  }).then(response => {
      let  res_data = response.data;
      console.log('LOCK_ITEM_SUCCES', res_data);
      dispatch(({ type: ActionTypes.LOCK_ITEM, payload: 'me' }))
  }).catch( error => {
      let  res_data = error.response.data;
      console.log('LOCK_ITEM_ERROR', res_data);
      dispatch(({ type: ActionTypes.LOCK_ITEM, payload: res_data.meta.locked_by }));
  })
})

export const keepLockItem = (id) => ((dispatch) => {

  const data = {
    id: id
  };

  axios({
      method: 'post',
      url: `${API_URL}/events/${id}/lock`,
      data
  }).then(response => {
      let  res_data = response.data;
      console.log('KEEP_LOCK_ITEM_SUCCES', res_data);
      // dispatch(({ type: ActionTypes.LOCK_ITEM, payload: 'me' }))
  }).catch( error => {
      let  res_data = error.response.data;
      console.log('KEEP_LOCK_ITEM_ERROR', res_data);
      dispatch(({ type: ActionTypes.LOCK_ITEM, payload: res_data.meta.locked_by }));
  })
})

export const unlockItem = (id) => ((dispatch) => {

  const data = {
    id: id
  };

  axios({
      method: 'post',
      url: `${API_URL}/events/${id}/unlock`,
      data
  }).then(response => {
      let  data = response.data.data;
      dispatch(({ type: ActionTypes.LOCK_ITEM, payload: null }))
  }).catch( error => {
      
  })
})
export const clearLockItem = () => ({ type: ActionTypes.LOCK_ITEM, payload: null });

// fetchYear
export const fetchItem = (year, id) => ((dispatch) => {
    dispatch(fetchItemBegin(id));
    axios.get(`${API_URL}/events/${id}`)
      .then(response => {
        let data = response.data;
        dispatch(fetchItemSuccess(year, data))
      })
      .catch(error => dispatch(fetchItemError(error)))
  }
)
export const fetchItemBegin = id => ({ type: ActionTypes.FETCH_ITEM_BEGIN, payload: id });
export const fetchItemSuccess = (year, data) => ({ type: ActionTypes.FETCH_ITEM_SUCCESS, payload: data, year: year });
export const fetchItemError = error => ({ type: ActionTypes.FETCH_ITEM_ERROR, payload: error });

// fetchYear
export const fetchYear = year => ((dispatch) => {
    dispatch(fetchYearBegin(year));
    // axios.get(`${API_URL}/${year}`)
    axios.get(`${API_URL}/events?year=${year}`)
      .then(response => {
        let  events = [];
        let meta = [];
        events[year] = response.data.data;
        meta[year] = response.data.meta;

        dispatch(fetchYearSuccess(events, meta))
      })
      .catch(error => dispatch(fetchYearError(error)))
  }
)
export const fetchYearBegin = year => ({ type: ActionTypes.FETCH_YEAR_BEGIN, payload: year });
export const fetchYearSuccess = (events, meta) => ({ type: ActionTypes.FETCH_YEAR_SUCCESS, payload: events, meta: meta });
export const fetchYearError = error => ({ type: ActionTypes.FETCH_YEAR_ERROR, payload: error });

// fetchLast
export const fetchLast = () => ((dispatch) => {
    dispatch(fetchLastBegin());
    axios.get(`${API_URL}/events?order_by=last_modified&limit=10`)
      .then(response => {
        let  events = [];
        events['last_modified'] = response.data.data;
        dispatch(fetchLastSuccess(events))
      })
      .catch(error => dispatch(fetchLastError(error)))
  }
)
export const fetchLastBegin = () => ({ type: ActionTypes.FETCH_LAST_BEGIN, payload: null });
export const fetchLastSuccess = data => ({ type: ActionTypes.FETCH_LAST_SUCCESS, payload: data });
export const fetchLastError = error => ({ type: ActionTypes.FETCH_LAST_ERROR, payload: error });


export const addComment = (year, item, comment) => ((dispatch) => {
  axios.post(`${API_URL}/events/${item.id}/comments`, comment)
    .then(response => {
      let  data = response.data.data;
      console.log(data, 'comment');
      dispatch(({ type: ActionTypes.ADD_COMMENT, payload: item, year : year, comment: data }))
    })
})

export const delComment = (year, item, comment_id) => ((dispatch) => {
  const data = {
    id : comment_id
  }
  axios.delete(`${API_URL}/events/comments/${comment_id}`, data)
    .then(response => {
      let  data = response.data.data;
      dispatch(({ type: ActionTypes.DEL_COMMENT, payload: item, year : year, comment_id: comment_id }))
    })
})
