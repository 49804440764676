export default [
  {
    label: 'Name',
    objKey : 'name',
    order: 1,
  },
  {
    label: 'From',
    objKey : 'start_date',
    order: 2,
  },
  {
    label: 'To',
    objKey : 'end_date',
    order: 3,
  },
  {
    label: 'City',
    objKey : 'city.name',
    order: 4,
  },
  {
    label: 'Country',
    objKey : 'country.country_iso',
    order: 5,
  },
  {
    label: 'Gender',
    objKey : 'gender',
    order: 6,
  },
  {
    label: 'Type of event',
    objKey : 'type',
    order: 7,
  },
  {
    label: 'Status',
    objKey : 'status',
    order: 14,
  },
  {
    label: 'Watch',
    objKey : 'marketing.watch',
    order: 8,
  },
  {
    label: 'Visual',
    objKey : 'marketing.visual',
    order: 9,
  },
  {
    label: 'Ambassador',
    objKey : 'marketing.ambassadors',
    order: 10,
  },
  {
    label: 'Management',
    objKey : 'marketing.management_presence',
    order: 11,
  },
  {
    label: 'Classification',
    objKey : 'classification',
    order: 12,
  },
  {
    label: 'Class',
    objKey : 'class',
    order: 13,
  },
];
