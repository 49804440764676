import { combineReducers } from 'redux';
import appReducer from './appReducer';
import eventReducer from './eventReducer';
import userReducer from './userReducer';
import logReducer from './logReducer';
import optionReducer from './optionReducer';

const app = appReducer;
const events = eventReducer;
const users = userReducer;
const logs = logReducer;
const options = optionReducer;

export default combineReducers({
  app,
  events,
  users,
  logs,
  options,
})
