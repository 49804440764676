import utils from '../../helpers/utils';

const actions = [
  "ADD_ITEM",
  "DEL_ITEM",

  "SEARCH",

  "LOGIN_WITH_TOKEN",
  "LOGIN_WITH_TOKEN_BEGIN",
  "LOGIN_WITH_TOKEN_SUCCESS",
  "LOGIN_WITH_TOKEN_ERROR",

  "LOGIN",
  "LOGIN_BEGIN",
  "LOGIN_SUCCESS",
  "LOGIN_ERROR",

  "LOGOUT",

  "UPD_SETTINGS",
  "EXPORT",

  "ACTION_MESSAGE_SUCCESS",
  "ACTION_MESSAGE_ERROR",
  "ACTION_MESSAGE_CLEAR",

  "ENABLE_SIDEBAR_CONFIRMATION",
  "DISABLE_SIDEBAR_CONFIRMATION"
];

export default utils.redux.createActionTypes('APP', actions );
