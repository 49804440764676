import React from "react";
import AppLink from './AppLink';
import appConfig from '../appConfig';

export default (props) => (
  <nav>
    <ul className="nav flex-column" >
      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
      <AppLink
        exact to="/dashboard"
        className="nav-link"
        isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
        <i className="fas fa-tachometer-alt"></i> Dashboard
      </AppLink>
      }

      <AppLink
        to="/events"
        className="nav-link"
        isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
        <i className="far fa-calendar-alt"></i>Events
      </AppLink>

      { props.loggedInUser.role === appConfig.role[2] &&
      <AppLink
        to="/users"
        className="nav-link"
        isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
        <i className="far fa-user"></i> Users
      </AppLink>
      }

      { props.loggedInUser.role === appConfig.role[2] &&
      <AppLink
        to="/logs"
        className="nav-link"
        isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
        <i className="fas fa-history"></i> Logs
      </AppLink>
      }

      { props.children }
    </ul>
  </nav>
);
