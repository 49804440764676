import React, { Component } from 'react';

class Checkbox extends Component {
  // static defaultProps = {}
  constructor(props){
    super(props);
    this.state = {
      value: props.value
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.value !== this.props.value){
      this.setState({
        value: this.props.value
      })
    }
  }
  updateData(){
    const { updateEditorData, disabled } = this.props;
    if(!disabled){
      const newValue = !this.props.value

      this.setState({
        value: newValue
      })

      updateEditorData && updateEditorData(newValue);
    }
  }
  render() {
    const { label, disabled } = this.props;
    const { value } = this.state;

    return (
      <div className="form-check" onClick={()=>{this.updateData()}}>
        <input className="form-check-input" type="checkbox" value={value} checked={value} disabled={disabled} />
        <label className="form-check-label">
          {label}
        </label>
      </div>
    );
  }
}

export default Checkbox
