import React, { Component } from 'react';

class Status extends Component {
  // static defaultProps = {}
  render() {
    const { status } = this.props;

    const classNames = {
      confirmed: 'badge-success',
      pending: 'badge-warning',
      cancelled: 'badge-danger'
    }

    return(
      <span className={"badge " + classNames[status] }>
        { status }
      </span>
    )
  }
}

export default Status
