import React, { Component } from 'react';
import Select, { components } from 'react-select';
import appConfig from '../../appConfig';
import $ from 'jquery';
import OptionManager from './OptionManager';
import utils from '../../helpers/utils';

const Option = props => {
  return (
    <>
      <components.Option {...props} />
    </>
  )
}

const ValueContainer = props => {
  const optionClick = (e, option) => {
    e.stopPropagation();
    e.preventDefault();

    const clicked = $(e.target).text();
    const values = option.getValue();
    const pos = values.findIndex((v)=>(v.code === clicked))

    if(pos > -1){
      let updValue = (values.slice(pos, pos + 1))[0];
      switch (updValue.status) {
        case 'pending':
            updValue.status = 'confirmed';
          break;
        case 'confirmed':
            updValue.status = 'cancelled';
          break;
        case 'cancelled':
            updValue.status = 'pending';
          break;
        default:
          updValue.status = 'pending';
      }
      const newValues = [...values.slice(0, pos), ...[updValue], ...values.slice(pos +1, values.length)]
      option.setValue(newValues);
    }

  }
  return (
    <div className="selected-values" onClick={(e)=>{ optionClick(e, props); }} >
      <Option {...props} />
    </div>
  );
};


class MultiTag extends Component {
  constructor(props){
    super(props);
    this.state = {
      isMenuOpen: false
    }
  }
  componentDidUpdate(prevProps){
    // check selectedOption against selected
    setTimeout(()=>{
      let { selected } = this.props;
      let selectedOptions = this.buildSelectedOptions();
      selected = selected || [];
      selectedOptions = selectedOptions || [];

      if(selected.length !== selectedOptions.length){
        this.updateData(selectedOptions);
      }
    }, 200)
  }
  updateData(data){
    const { updateEditorData } = this.props;
    updateEditorData && updateEditorData(data);
  }
  toggleMenu(e){
    e.stopPropagation();
    e.preventDefault();

    const { isMenuOpen } = this.state;

    if(!$(e.target).parents().hasClass('select__menu')){
      this.setState({
        isMenuOpen: !isMenuOpen
      })
    }
  }
  buildSelectedOptions(){
    let { selected, options } = this.props;
    selected = selected || [];
    options = options || [];

    let selectedOptions = selected.map((option)=>({
        id: option.id,
        code: option.code,
        value: option.code,
        label: option.code,
        status: option.status,
        group: option.code,
      }));

    // ensure selectedOption.code is present in options
    selectedOptions = selectedOptions.filter((selectedOption)=>{
      let found = false;
      options.forEach((option)=>{
        if(selectedOption.code === option.code){
          found = true;
          return;
        }
      })
      return found;
    });

    return selectedOptions;
  }
  render() {
    const { disabled, optionManagerEnabled, optionManagerTitle, addOption, updOption, delOption } = this.props;
    let { selected } = this.props;
    let { options } = this.props;
    const { isMenuOpen } = this.state;

    selected = selected || [];
    options = options || [];

    const selectedOptions = this.buildSelectedOptions();

    // Group
    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      padding: '0',
      backgroundColor: '#aaa'
    };

    // const formatOptionLabel = (data, ctx ) => {
    //   // console.log(data);
    //   // console.log(ctx);
    //
    //   // const onLabelClick = ctx.context === 'value' ? (e) => {
    //   //   e.stopPropagation();
    //   //   e.preventDefault();
    //   //   console.log('OPTION CLICK')
    //   // } : () => {};
    //
    //   return(
    //     <>
    //       <>
    //         { ctx.context === 'value' &&
    //           <div className="option-label-custom" onClick={(e)=>{
    //               e.preventDefault();
    //               e.stopPropagation();
    //
    //               console.log('OPTION CLICK')
    //             }}>
    //             {data.label}
    //           </div>
    //         }
    //       </>
    //       <>
    //         { ctx.context !== 'value' &&
    //           <div className="option-label">
    //             {data.label} menu
    //           </div>
    //         }
    //       </>
    //     </>
    //
    //   );
    // }

    const formatGroupLabel = data => {
      return(
        <div className="option-group" style={groupStyles}>

        </div>
      );
    }

    // Options styles
    const optionStyles =  {
      menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const border = '1px solid #f1f2f4';
        const boxShadow = '0 10px 15px rgba(190, 190, 208, 0.2)';
        return {
          ...styles,
          border,
          boxShadow,
        };
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const borderRadius = '3px';
        const color = '#fff';
        const display = 'inline-block';
        const width = 'auto';
        const marginTop = '5px';
        const marginBottom = '5px';
        const marginLeft = '5px';
        const padding = '2px 8px';
        const fontSize = '85%';

        let backgroundColor;
        if(data){
           backgroundColor = appConfig.colors[data.status];
        }

        return {
          ...styles,
          borderRadius,
          color,
          display,
          width,
          marginTop,
          marginBottom,
          marginLeft,
          padding,
          fontSize,
          backgroundColor,
        };
      },
      multiValue: (styles, { data }) => {
        const color = '#fff !important';
        const display = 'flex';
        const width = 'auto';
        const backgroundColor = appConfig.colors[data.status];
        return {
          ...styles,
          color,
          display,
          width,
          backgroundColor
         }
      },
      multiValueLabel: (styles, { data }) => {
        const color = '#fff !important';
        return {
          ...styles,
          color,

         }
      },
    };

    const createOption = (option) => ({
        label: option.code,
        options: [
          {
            id: option.id,
            code: option.code,
            value: option.code,
            label: option.code,
            status: 'confirmed',
            group: option.code,
          },
          {
            id: option.id,
            code: option.code,
            value: option.code,
            label: option.code,
            status: 'pending',
            group: option.code,
          },
          {
            id: option.id,
            code: option.code,
            value: option.code,
            label: option.code,
            status: 'cancelled',
            group: option.code,
          }
        ],
      }
    );

    // sort options
    options = utils.sortByKey(options, 'code', true, true);

    const optionList = options.map((option)=>{
      option.code = option.code || option.name
      return(createOption(option));
    });

    return (
      <>
      <div className="d-flex">
        <div className="mr-auto" style={{width: '100%'}} onClick={(e)=>{ this.toggleMenu(e) }} onBlur={()=>{ this.setState({isMenuOpen: false}); }}>
          <Select
            styles={optionStyles}
            value={selectedOptions}
            isMulti
            onChange={(o)=>{ this.updateData(o) }}
            options={optionList}
            isSearchable={true}
            isClearable={true}
            isDisabled={disabled}
            classNamePrefix='select'
            formatGroupLabel={formatGroupLabel}
            components={{ ValueContainer }}
            menuIsOpen={ !disabled && isMenuOpen}
          />
        </div>

        { ! disabled && optionManagerEnabled &&
        <div className="ml-1">
          <OptionManager
            selected={selected[selected.length - 1]}
            options={options}
            optionManagerTitle={optionManagerTitle}
            addOption={(optionData)=>{ addOption(optionData) }}
            updOption={(optionData)=>{ updOption(optionData) }}
            delOption={(optionData)=>{ delOption(optionData) }}
            />
        </div>
        }
      </div>
      </>
    );
  }
}

export default MultiTag
