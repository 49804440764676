import axios from 'axios';

const TOKEN_KEY = 'eventmanager:api_token' ;
var oAxios = axios.create();

oAxios.interceptors.request.use(function (config) {
const token = sessionStorage.getItem(TOKEN_KEY);

if (token) {
  config.headers.Authorization = `Bearer ${token}`;
}

return config;
});

export default oAxios;
