import React, { Component } from 'react';
import AppNavigation from './AppNavigation';
import logo from '../assets/imgs/longines-emp-logo.svg';
import { Modal } from './Button';

class AppSidebar extends Component {
  render() {
    const { loggedInUser, logout, isSidebarConfirmationEnabled } = this.props;
    return (
        <aside className="app-sidebar">
          <div className="app-logo">
            <figure>
              <img src={logo} alt="Longines Event Manager Platform" />
            </figure>
          </div>

          <div className="mt-auto mb-auto">
            <AppNavigation
              loggedInUser={loggedInUser}
              isSidebarConfirmationEnabled={ isSidebarConfirmationEnabled }
              >

              <li>
                { !isSidebarConfirmationEnabled &&
                <button href="#" className="nav-link" onClick={()=>{ logout() }}><i className="fas fa-sign-out-alt"></i> Log out</button>
                }

                { isSidebarConfirmationEnabled &&
                  <Modal buttonText={<><i className="fas fa-sign-out-alt"></i> Log out</>} title="Discard changes" tooltipText="Cancel" variant="link" confirm={()=>{ logout() }}>
                    <p>Do you really want to discard all your modifications ?</p>
                  </Modal>
                }
              </li>
            </AppNavigation>
          </div>
        </aside>
    );
  }
}

export default AppSidebar
