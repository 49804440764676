import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loading from '../helpers/Loading';
import appConfig from '../appConfig';

const pages = [
  'Dashboard',
  'Events',
  'Users',
  'Logs',
  'Tools',
  'Logout',
  'tools/Categories',
  'tools/CustomFields',
  'tools/Tokens',
  'Profile',
  'Documentation',
  'Error_404'
];

const asyncRoutes = pages.map( page => (
    Loadable({
      loader: () => import("../pages/" + page ),
      loading: Loading
    })
))

const getAsyncRoute = (pageName) => {
  const index = pages.indexOf(pageName);
  return asyncRoutes[index];
}


export default (props) => (
  <Fragment>
    <Switch>
      <Route exact path="/">
        <Redirect to="/events" />
      </Route>

      <Route exact path="/profile" component={ getAsyncRoute('Profile') } />
      <Route path="/documentation" component={ getAsyncRoute('Documentation') } />

      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
      <Route exact path="/dashboard" component={ getAsyncRoute('Dashboard') } />
      }

      <Route path="/events" component={ getAsyncRoute('Events') } />

      { props.loggedInUser.role === appConfig.role[2] &&
        <Route path="/users" component={ getAsyncRoute('Users') } />
      }

      { props.loggedInUser.role === appConfig.role[2] &&
        <Route path="/logs" component={ getAsyncRoute('Logs') } />
      }

{/*   
      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
        <Route path="/tools/categories" component={ getAsyncRoute('tools/Categories') } />
      }

      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
        <Route path="/tools/custom-fields" component={ getAsyncRoute('tools/CustomFields') } />
      }

      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
        <Route path="/tools/tokens" component={ getAsyncRoute('tools/Tokens') } />
      }

      { ( props.loggedInUser.role === appConfig.role[2] || props.loggedInUser.role === appConfig.role[1] ) &&
        <Route exact path="/tools" component={ getAsyncRoute('Tools') } />
      }
*/}

      <Route component={ getAsyncRoute('Error_404') } />

    </Switch>
  </Fragment>
)
