import React, { Component } from 'react';

class Input extends Component {
  static defaultProps = {
   value: "",
   type: "text"
  }
  constructor(props){
    super(props);
    this.state = {
      inputMode: props.disabled
    }
  }
  updateData(value){
    const {updateEditorData } = this.props;
    updateEditorData && updateEditorData(value);
  }
  toggleMode(){
    this.setState({
      inputMode: !this.state.inputMode
    })
  }
  componentDidUpdate(){
    if(this.state.inputMode){
      this.input && this.input.focus();
    }
  }
  render() {
    const { disabled, name, type, className } = this.props;
    const { inputMode } = this.state;
    let { value } = this.props;
    value = value || "";

    return (
      <div className={"input-togglable " + className}>
        { inputMode &&
          <input
            ref={(input) => { this.input = input }}
            type={type}
            className={"form-control "}
            name={name} value={value}
            onChange={(e)=>{ this.updateData(e.target.value) }}
            onBlur={()=>{ this.toggleMode() }}
            />
        }

        { !inputMode &&
          <h1 className={className} onClick={()=>{ !disabled && this.toggleMode() }}>
            {value}
          </h1>
        }

      </div>

    );
  }
}

export default Input
