import utils from '../../helpers/utils';

const actions = [
  "FETCH_CHUNK",
  "FETCH_CHUNK_BEGIN",
  "FETCH_CHUNK_SUCCESS",
  "FETCH_CHUNK_ERROR",
];

export default utils.redux.createActionTypes('LOG', actions );
