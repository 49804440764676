export default {
  status: ['confirmed', 'pending', 'cancelled'],
  country :[],
  city : [],
  gender : [],
  type : [],
  classification : [],
  class : [],
  marketing: {
    "management_presence": [],
    "visual": [],
    "watch": [],
    "ambassadors": [],
    "longines_timing_specificities": null
  }
}
