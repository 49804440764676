import cloneDeep from 'lodash/cloneDeep';
import ActionTypes from "../actionTypes/logActionTypes";

const initialState = {
  collection : [],
  recentlyAdded: null
}
const ItemReducer = (itemsState = initialState, action) => {
  switch (action.type) {

    case ActionTypes.FETCH_CHUNK_SUCCESS:
      let fetchedState = cloneDeep(itemsState);

      fetchedState.collection = [...action.payload];

      return fetchedState ;

    default:
        return itemsState;
  }
};

export default ItemReducer
