import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

class DropdownButton extends Component {
  static defaultProps = {
    current: 2020
  }
  constructor(props){
    super(props);
    this.state = {
      current: props.current,
      isDropdownShown: false,
    };

    this.dropdownMenuRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentWillUnmount() {
    // Remove click handler anyway when component is destroyed
    document.removeEventListener('click', this.toggleModal);
  }

  toggleModal(event) {
    const { isDropdownShown } = this.state;

    // Bail early if the target is a child of the dropdown menu and is
    // something other than an anchor (keeps the modal opened)
    if (event && this.dropdownMenuRef.current) {
      if (this.dropdownMenuRef.current.contains(event.target)) {
        if (event.target.tagName !== 'A') {
          return;
        }
      }
    }

    if (isDropdownShown) {
      // Modal was shown, let's remove the click handler
      document.removeEventListener('click', this.toggleModal);
    } else {
      // Modal is about to be shown, let's add the click handler
      document.addEventListener('click', this.toggleModal);
    }

    this.setState({
      isDropdownShown: !isDropdownShown,
    });
  }

  setCurrent(str){
    // this.setState({
    //   current: str
    // })
  }
  render() {
    const { label, size, variant, children } = this.props;
    const { isDropdownShown } = this.state;

    // const current = match.params.year;
    // const items = [2019, 2020, 2021, 2022];

    // const itemList = items.map((year)=>(
    //   <Dropdown.Item as={Link} to={"/events/" + year} onClick={()=>{ this.setCurrent(year) }}>
    //       {item}
    //   </Dropdown.Item>
    // ))

    return (
      <Dropdown show={isDropdownShown}>
        <Dropdown.Toggle
          variant={variant} size={size}
          id="dropdown-basic"
          onClick={this.toggleModal}
        >
          { label }
        </Dropdown.Toggle>

        <Dropdown.Menu ref={this.dropdownMenuRef}>
          {children}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

DropdownButton.Item = Dropdown.Item;

export default DropdownButton;
