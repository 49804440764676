import React, { Component } from 'react';

class Input extends Component {
  static defaultProps = {
   value: "",
   type: "text",
  }
  updateData(value){
    const {updateEditorData } = this.props;
    updateEditorData && updateEditorData(value);
  }
  render() {
    const { disabled, name, type, className } = this.props;
    let { value } = this.props;
    value = value || "";

    return (
      <div className={"input-togglable " + className}>
        { !disabled &&
          <input
            key={"input-togglable"}
            ref={(input) => { this.input = input }}
            type={type}
            className={"form-control "}
            name={name} value={value}
            onChange={(e)=>{ this.updateData(e.target.value) }}
            />
        }

        { disabled &&
          <h1 className={className} onClick={()=>{ !disabled && this.toggleMode() }}>
            {value}
          </h1>
        }

      </div>

    );
  }
}

export default Input
