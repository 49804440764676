import React, { Component } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Input } from './Input';

let timeoutRef;
class Searchbar extends Component {
  static defaultProps = {
    searchValue: ''
  }
  constructor(props){
    super(props);
    this.state = {
      searchValue: props.searchValue
    }
  }
  updateData(data){
    clearTimeout(timeoutRef);
    this.setState({
      searchValue: data
    });
  }
  clearData(){
    clearTimeout(timeoutRef);
    this.setState({
      searchValue: ''
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    if(prevState.searchValue !== this.state.searchValue){
      clearTimeout(timeoutRef);
      timeoutRef = setTimeout(()=>{
        onChange && onChange(this.state.searchValue);
      }, 0)
    }

    if(this.props.searchValue ==='' && this.props.searchValue !== this.state.searchValue && prevProps.searchValue !== this.props.searchValue){
      this.setState({
        searchValue: this.props.searchValue
      });
    }
  }
  render() {
    const { placeholder } = this.props;
    const { searchValue } = this.state;
    return (
      <div className="searchbar">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="searchbox-label"><i className="fas fa-search"></i></InputGroup.Text>
          </InputGroup.Prepend>
          <Input
            className="form-control"
            placeholder={placeholder}
            aria-label="searchbox"
            aria-describedby="searchbox-label"
            id="searchbox"
            value={searchValue}
            updateEditorData={(data)=>{ this.updateData(data) }}
          />
        { searchValue !== '' &&
          <InputGroup.Append>
              <InputGroup.Text id="searchbox-label"><i className="fas fa-times" onClick={()=>{ this.clearData()  }}></i></InputGroup.Text>
          </InputGroup.Append>
        }
        </InputGroup>
      </div>
    );
  }
}

export default Searchbar
