import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Counter to have unique IDs
let i = 0;

const ConditionalTooltip = (props) => {
  const { tooltipText, delay, placement, children } = props;
  const id = `react-tooltip-${i++}`;

  if (tooltipText) {
    return (
      <OverlayTrigger
        placement={placement}
        delay={delay}
        overlay={<Tooltip id={id}>{tooltipText}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    );
  } else {
    return children;
  }
};

ConditionalTooltip.defaultProps = {
  tooltipText: null,
  delay: {
    show: 1000,
    hide: 0,
  },
  placement: 'bottom',
};

export default ConditionalTooltip;