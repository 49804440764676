import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import logo from '../assets/imgs/longines-emp-logo.svg';
import longinesLogo from '../assets/imgs/longines-logo-neg.svg';
import Notification from '../components/Core/Notification';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    }
  }
  login(){
    const { username, password } = this.state;
    this.props.login(username, password)
    // if( username === 'full-control@example.com'  &&
    //     password === 'demo@demo'
    //   ){
    //   this.props.login()
    // }
  }
  render() {
    const { username, password } = this.state;
    const { actionMessage } = this.props;

    return (
      <div className="login d-flex animated fadeIn">
        <div className="welcome mt-auto mb-auto ml-auto">
          <div className="p-md-3">
          <h1>
            Welcome
            <small className="d-block">To Longines Event Management Platform</small>
          </h1>
          <div className="credits">A web application developped by <img src={longinesLogo} /></div>
          </div>
        </div>
        <div className="loginbar p-3 ml-auto">
          <div className="mt-auto mb-auto">
            <div className="app-logo">
              <figure>
                <img src={logo} alt="Longines Event Management Platform" />
              </figure>
            </div>

            <form className="login-form" onSubmit={(e)=>{
                e.stopPropagation();
                e.preventDefault();
                this.login()
                }}>

              {actionMessage.message &&
                <Notification message={this.props.actionMessage.message} statusCode={this.props.actionMessage.code} />
              }
              <div className="form-group">
                <input placeholder="LON-XXX / xxx@longines.com" type="text" className="form-control" id="username" value={ username } onChange={(e)=>{ this.setState({ username: e.target.value }) }} />
              </div>
              <div className="form-group">
                <input placeholder="Your Longines Password" type="password" className="form-control" id="password" value={ password } onChange={(e)=>{ this.setState({ password: e.target.value }) }} />
              </div>
              <div className="p2 text-center">
                  <Button type="submit">
                    Sign In
                  </Button>
              </div>
            </form>

          </div>
        </div>
      </div>
    );
  }
}

export default Login
