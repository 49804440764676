import utils from '../../helpers/utils';

const actions = [
  "FETCH_YEAR",
  "FETCH_YEAR_BEGIN",
  "FETCH_YEAR_SUCCESS",
  "FETCH_YEAR_ERROR",

  "FETCH_COUNTRY",
  "FETCH_COUNTRY_BEGIN",
  "FETCH_COUNTRY_SUCCESS",
  "FETCH_COUNTRY_ERROR",

  "FETCH_CITY",
  "FETCH_CITY_BEGIN",
  "FETCH_CITY_SUCCESS",
  "FETCH_CITY_ERROR",

  "FETCH_GENDER",
  "FETCH_GENDER_BEGIN",
  "FETCH_GENDER_SUCCESS",
  "FETCH_GENDER_ERROR",
  "ADD_GENDER",
  "UPD_GENDER",
  "DEL_GENDER",

  "FETCH_TYPE",
  "FETCH_TYPE_BEGIN",
  "FETCH_TYPE_SUCCESS",
  "FETCH_TYPE_ERROR",
  "ADD_TYPE",
  "UPD_TYPE",
  "DEL_TYPE",

  "FETCH_CLASSIFICATION",
  "FETCH_CLASSIFICATION_BEGIN",
  "FETCH_CLASSIFICATION_SUCCESS",
  "FETCH_CLASSIFICATION_ERROR",
  "ADD_CLASSIFICATION",
  "UPD_CLASSIFICATION",
  "DEL_CLASSIFICATION",

  "FETCH_CLASS",
  "FETCH_CLASS_BEGIN",
  "FETCH_CLASS_SUCCESS",
  "FETCH_CLASS_ERROR",
  "ADD_CLASS",
  "UPD_CLASS",
  "DEL_CLASS",

  "FETCH_MARKETING",
  "FETCH_MARKETING_BEGIN",
  "FETCH_MARKETING_SUCCESS",
  "FETCH_MARKETING_ERROR",
  "ADD_MARKETING",
  "UPD_MARKETING",
  "DEL_MARKETING",

  "FETCH_ALL",
  "FETCH_ALL_BEGIN",
  "FETCH_ALL_SUCCESS",
  "FETCH_ALL_ERROR",

  "ADD_OPTION",
  "UPD_OPTION",
  "DEL_OPTION",

];

export default utils.redux.createActionTypes('OPTIONS', actions );
