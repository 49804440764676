import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import * as Actions from "../redux/actions/appActions";
import Searchbar from "../components/Searchbar";

const mapStateToProps = state => {
  return { searchValue: state.app.searchValue };
};
const mapDispatchToProps = dispatch => {
  return {
    search: value => dispatch(Actions.search(value)),
  };
};

class AppSearchbar extends Component {
  static defaultProps = {
    items : []
  }
  search(searchValue){
    this.props.search(searchValue);
  }
  render(){
    const { searchValue } = this.props;

    return(
      <div>
        <Searchbar searchValue={searchValue} onChange={(value)=>{ this.search(value) }} />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppSearchbar));
