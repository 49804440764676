import React, { Component } from 'react';
import Searchbar from '../containers/Searchbar';
import { Container } from 'react-bootstrap';
import { User } from './Field';
import { Dropdown } from './Button';
import AppLink from './AppLink';
import { Modal } from './Button';

class AppHeader extends Component {
  render() {

    const { toggleButton, loggedInUser, logout, isSidebarConfirmationEnabled } = this.props;

    // console.log(loggedInUser, 'loggedInUser');

    return (
      <header className="app-header fixed-top">
        <Container fluid={true}>
          <div className="inner">

            { toggleButton }

            <Searchbar />
            <div className="notification-header">
            </div>

            <div className="user-header">

              <Dropdown
                variant="link"
                label={
                  <User
                avatar={loggedInUser.avatar}
                name={loggedInUser.name}
                role={loggedInUser.role}
                />
              }>

              <ul className="nav flex-column" >
                <AppLink
                  to="/profile"
                  className="nav-link"
                  isConfirmationModalEnabled={isSidebarConfirmationEnabled}
                  >
                   Profile
                </AppLink>
                <AppLink
                  to="/documentation"
                  className="nav-link"
                  isConfirmationModalEnabled={isSidebarConfirmationEnabled}
                  >
                   Documentation
                </AppLink>

                <li className="nav-item">

                  { !isSidebarConfirmationEnabled &&
                  <button className="nav-link" onClick={()=>{ logout() }}>
                    Log out
                  </button>
                  }

                  { isSidebarConfirmationEnabled &&
                    <Modal buttonText={"Log out"} title="Discard changes" tooltipText="Cancel" variant="link" confirm={()=>{ logout() }}>
                      <p>Do you really want to discard all your modifications ?</p>
                    </Modal>
                  }
                </li>
              </ul>

              </Dropdown>

            </div>
          </div>
        </Container>
      </header>
    );
  }
}

export default AppHeader;
