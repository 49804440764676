import React, { Component } from 'react';
import "loaders.css";

const classNames = {
  '200': ' alert-success',
  '500': ' alert-danger',
};

class Alert extends Component {
  static defaultProps = {}
  render() {
    const { message, statusCode } = this.props;

    const className = classNames[statusCode.toString()];

    return (
      <div className={"notification shadow alert" + className}>
        {message}
      </div>
    );
  }
}

export default Alert
