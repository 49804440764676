import React, { Component } from 'react';
import utils from '../../helpers/utils';

import Avatar from './Avatar';
import User from './User';
import Status from './Status';
import { MultiBadge } from '../Field';

class DataViewer extends Component {
  // static defaultProps = {
  //  stats: []
  // }
  render() {
    const { objKey, label, data } = this.props;

    if(objKey === 'user'){
      return(
        <td>
          <User avatar={data.avatar} name={data.name} size='sm'></User>
        </td>
      )
    }


    if(
      objKey === 'marketing.management_presence' ||
      objKey === 'marketing.watch' ||
      objKey === 'marketing.visual' ||
      objKey === 'marketing.ambassadors'
    ){
      return(
        <td>
          <MultiBadge
            disabled={true}
            showAdder={false}
            selected={ data } />
        </td>
      )
    }

    if(label === 'Avatar'){
      return(
        <td>
          <Avatar avatar={data}></Avatar>
        </td>
      )
    }

    if(label === 'Name'){
      return(
        <td className="item-name">
          <strong>
            {data}
          </strong>
        </td>
      )
    }

    if(label === 'Status'){
      return(
        <td className="item-status">
          <Status status={data} />
        </td>
      )
    }

    if(label === 'Date' ||
       label === 'Last update'
    ){
      return(
        <td className="item-date">
          {utils.formatDate(data)}
        </td>
      )
    }

    if(label === 'From' ||
       label === 'To'
    ){
      return(
        <td className="item-date">
          {utils.formatEventDate(data)}
        </td>
      )
    }

    if(data && label === 'Country' ){
      return(
        <td className="item-country">
          <div className="country d-flex">
            <div className="flag">
              <span className={"flag-icon flag-icon-"+data.toLowerCase() }></span>
            </div>
            <div className="code">
              {data}
            </div>
          </div>
        </td>
      )
    }

    if(typeof data === "object" ){

      if(data.code){
        return(
          <td>
            {data.code}
          </td>
        )
      }

      return(
        <td>
          {utils.toJSON(data)}
        </td>
      )
    }

    return(
      <td>
        {data}
      </td>
    )
  }
}

export default DataViewer
