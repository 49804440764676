import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ConditionalTooltip from './ConditionalTooltip';

class Popup extends Component {
  constructor(props){
    super(props);
    this.state = {
      show: props.show || false,
    }
  }
  componentDidUpdate(prevProps){
    const { forceClose, forceShow } = this.props;

    if(forceClose && !prevProps.forceClose){
      this.setState({
        show: false
      })
    }

    if(forceShow && !this.state.show){
      this.setState({
        show: true
      })
    }

  }
  handleShow(){
    this.setState({
      show: true
    })
    if(this.props.openCallback){
      this.props.openCallback();
    }
  }
  handleClose(){
    this.setState({
      show: false
    })
    if(this.props.closeCallback){
      this.props.closeCallback();

      // Have to use a time out since the modal restores the focus on the element that triggered it
      // Modal onExited event can't be used because a Transition component must be used to trigger the event
      window.setTimeout(() => {
        document.activeElement.blur();
      }, 0);
    }
  }
  confirm(){
    const { confirm } = this.props;
    confirm && confirm();
    this.handleClose();
  }
  render() {
    const { show } = this.state;

    const { disabled, variant, size, buttonText, title, className, confirmButtonText, children, tooltipText } = this.props;

    const confirmButtonTextData = confirmButtonText ? confirmButtonText : 'Confirm';

    return (
        <>
          { buttonText &&
            <ConditionalTooltip tooltipText={tooltipText}>
              <Button disabled={disabled} variant={variant}  className={className} onClick={()=>{ this.handleShow() }}>
                {buttonText}
              </Button>
            </ConditionalTooltip>
          }

          <Modal size={size} centered show={show} onHide={()=>{ this.handleClose() }} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {children}
            </Modal.Body>
            { this.props.confirm  &&
              <Modal.Footer>
                <Button variant="default" onClick={()=>{ this.handleClose() }}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={()=>{ this.confirm() }}>
                  {confirmButtonTextData}
                </Button>
              </Modal.Footer>
            }

          </Modal>
        </>
    );
  }
}

export default Popup
