import cloneDeep from 'lodash/cloneDeep';
import ActionTypes from "../actionTypes/eventActionTypes";

const initialState = {
  collection : [],
  meta: [],
  recentlyAdded: {},
  lockedBy: null,
  filters: {
    start_date: null,
    end_date: null,
    country: null,
    city: null,
    gender: null,
    type: null,
    classification: null,
    class: null,
    status: null,
    marketing: {
      watch: null,
      visual: null,
      ambassadors: null,
      management_presence: null,
    }
  },
}
const eventReducer = (itemsState = initialState, action) => {
  switch (action.type) {

    case ActionTypes.CLEAR_COLLECTION:
      let clearedState = initialState;
      return clearedState ;

    case ActionTypes.LOCK_ITEM:
      let lockedState = cloneDeep(itemsState);
      lockedState.lockedBy = action.payload;
      return lockedState ;

    case ActionTypes.UPD_FILTERS:
      let updFiltersState = cloneDeep(itemsState);
      updFiltersState.filters = action.payload;
      return updFiltersState ;

    case ActionTypes.RESET_FILTERS:
      let resetedFiltersState = cloneDeep(itemsState);
      resetedFiltersState.filters = initialState.filters;
      return resetedFiltersState ;

    case ActionTypes.CLEAR_RECENTLY_ADDED:
      let clearedRecentlyAddedState = cloneDeep(itemsState);

      clearedRecentlyAddedState.recentlyAdded = initialState.recentlyAdded;

      return clearedRecentlyAddedState ;

    case ActionTypes.ADD_ITEM:
      const addedItemState = cloneDeep(itemsState);

      addedItemState.recentlyAdded = action.payload;

      // store in collection
      addedItemState.collection[action.year].push(action.payload);

      return addedItemState;

    case ActionTypes.DEL_ITEM:
        const id = action.payload;
        const deletedItemState = cloneDeep(itemsState);

        const posOfId = itemsState.collection[action.year].findIndex((e) => {
          return e.id === id;
        });

        deletedItemState.collection[action.year] = [ ...itemsState.collection[action.year].slice(0, posOfId), ...itemsState.collection[action.year].slice(posOfId + 1)];

        return  deletedItemState;

    case ActionTypes.UPD_ITEM:
        const updatedItemState = cloneDeep(itemsState);

        const posOfIdToUpd = itemsState.collection[action.year].findIndex((e) => {
          return e.id === action.payload.id;
        });
        updatedItemState.collection[action.year][posOfIdToUpd] = action.payload;

        // create if not found
        if(posOfIdToUpd < 0){
          updatedItemState.collection[action.year].push(action.payload);
        }

        return  updatedItemState;

    case ActionTypes.FETCH_ITEM_SUCCESS:
        const fetchedItemState = cloneDeep(itemsState);

        const posOfIdToRenew = fetchedItemState.collection[action.year].findIndex((e) => {
          return e.id === action.payload.id;
        });
        fetchedItemState.collection[action.year][posOfIdToRenew] = action.payload;

        // create if not found
        if(posOfIdToRenew < 0){
          fetchedItemState.collection[action.year].push(action.payload);
        }

        // console.log('refreshed item',fetchedItemState.collection[action.year][posOfIdToRenew])

        return  fetchedItemState;

    case ActionTypes.FETCH_YEAR_SUCCESS:
      let fetchedState = cloneDeep(itemsState);

      fetchedState.collection = Object.assign({}, itemsState.collection, action.payload);
      fetchedState.meta = Object.assign({}, itemsState.meta, action.meta);
      return fetchedState ;

    case ActionTypes.FETCH_LAST_SUCCESS:

    let fetchedLastEventState = cloneDeep(itemsState);

    fetchedLastEventState.collection = Object.assign({}, itemsState.collection, action.payload);
    return fetchedLastEventState ;

    // COMMENTS
    case ActionTypes.ADD_COMMENT:
        const commentedItemState = cloneDeep(itemsState);

        const posOfIdToAddComment = itemsState.collection[action.year].findIndex((e) => {
          return e.id === action.payload.id;
        });
        commentedItemState.collection[action.year][posOfIdToAddComment]['comments'].push(action.comment);

        return  commentedItemState;

    case ActionTypes.DEL_COMMENT:
        const delCommentedItemState = cloneDeep(itemsState);

        const posOfIdToDelComment = itemsState.collection[action.year].findIndex((e) => {
          return e.id === action.payload.id;
        });

        const posOfCommentToDel = delCommentedItemState.collection[action.year][posOfIdToDelComment]['comments'].findIndex((e) => {
          return e.id === action.comment_id;
        });

        delCommentedItemState.collection[action.year][posOfIdToDelComment]['comments'] = [ ...delCommentedItemState.collection[action.year][posOfIdToDelComment]['comments'].slice(0, posOfCommentToDel), ...delCommentedItemState.collection[action.year][posOfIdToDelComment]['comments'].slice(posOfCommentToDel + 1)];

        return  delCommentedItemState;

    default:
        return itemsState;
  }
};

export default eventReducer
