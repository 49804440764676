import cloneDeep from 'lodash/cloneDeep';
import ActionTypes from "../actionTypes/appActionTypes";
import columnDef from "../../ressources/event/columnDefinitions";

const initialState = {
    loggedInUser: null,
    token: null,
    appInLoading : true,
    searchValue: null,
    isSidebarConfirmationEnabled: false,
    actionMessage: {
      message: '',
      data: null,
      code: 0,
    },
    settings: {
      columnToShow: columnDef.slice(0, 8)
    },
    exportUrl: null

}
const AppReducer = (appState = initialState, action) => {
  switch (action.type) {

    // Action message
    case ActionTypes.ACTION_MESSAGE_CLEAR:
      let clearedActionState = Object.assign({}, appState, {actionMessage : action.payload});
      return clearedActionState ;

    case ActionTypes.ACTION_MESSAGE_SUCCESS:
      let successActionState = Object.assign({}, appState, {actionMessage : action.payload});
      return successActionState ;

    case ActionTypes.ACTION_MESSAGE_ERROR:
      let errorActionState = Object.assign({}, appState, {actionMessage : action.payload});
      return errorActionState ;

    // Sidebar
    case ActionTypes.ENABLE_SIDEBAR_CONFIRMATION:
      let enabledSidebarConfirmationActionState = Object.assign({}, appState, {isSidebarConfirmationEnabled : true });
      return enabledSidebarConfirmationActionState ;

    case ActionTypes.DISABLE_SIDEBAR_CONFIRMATION:
      let disabledSidebarConfirmationActionState = Object.assign({}, appState, {isSidebarConfirmationEnabled : false });
      return disabledSidebarConfirmationActionState ;

    // Search
    case ActionTypes.SEARCH:
        let newState2 = Object.assign({}, appState, {appInLoading : action.payload});
        newState2.searchValue = action.payload;
      return newState2 ;

    case ActionTypes.UPD_SETTINGS:
      let upsSettingsState = cloneDeep(appState);

      upsSettingsState.settings = Object.assign({}, appState.settings,  action.payload);
      console.log(upsSettingsState, 'upsSettingsState');
      return upsSettingsState ;

    case ActionTypes.EXPORT:
      let exportsState = Object.assign({}, appState, {exportUrl : action.payload});
      return exportsState ;

    case ActionTypes.LOGIN_SUCCESS:
        let loginState = Object.assign({}, appState, {
          token : action.payload.access_token,
          loggedInUser : action.payload.user,
          settings: Object.assign({}, appState.settings, action.payload.user.settings)
        });
        // TODO: Send the full user obj with the token inside

      return loginState ;

    // case ActionTypes.LOGIN_ERROR:
    //     let loginErrorState = Object.assign({}, appState, {
    //       loggedInUser : 'error',
    //     });
    //
    //   return loginErrorState ;

    case ActionTypes.LOGOUT:
        let logoutState = Object.assign({}, appState, {
          token : null,
          loggedInUser : null,
        });
        // TODO: Send the full user obj with the token inside

      return logoutState ;

    default:
        return appState;
  }

};

export default AppReducer
